export const reset = (objData) => {
	debugger
	for (const key in objData) {

		if (Object.hasOwnProperty.call(objData, key)) {
			if (objData[key] instanceof Array){
				objData[key] = []
			}else {
				objData[key] = null
			}

		}
	}
}
export const validatePass = (rule, value, callback, password) => {
	if (!value) {
		callback(new Error('请再次输入密码'))
	} else if (value !== password) {
		callback(new Error('两次输入密码不一致!'))
	} else {
		callback()
	}
}
//验证手机
export const validatePhone = (rule, value, callback) => {
	if (!value) {
		return callback(new Error('请输入手机号'))
	}
	setTimeout(() => {
		if (/^1(3|4|5|6|7|8)\d{9}$/.test(value)) {
			callback()
		} else {
			callback(new Error('手机号格式不正确'))
		}
	}, 100)
}
//验证邮箱
export const validateEmail = (rule, value, callback) => {
	const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
	if (!value) {
		return callback()
	}
	setTimeout(() => {
		if (mailReg.test(value)) {
			callback()
		} else {
			callback(new Error('请输入正确的邮箱格式'))
		}
	}, 100)
}

/**按钮权限 */
const buttonArr = []
export const getButtons = (routes) => {
	routes.forEach((route) => {
		if (route.isMenu) {
			buttonArr.push(route.menuName)
		}
		if (route.userMenuModels.length) {
			getButtons(route.userMenuModels)
		}
	})
	return buttonArr
}

export const reMoveButtons = () => buttonArr.splice(0, buttonArr.length)
